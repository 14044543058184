import React from "react"
import Seo from "../components/Seo"
const insights = () => {
  return (
    <>
      <h2>insights page</h2>
    </>
  )
}

export default insights
